<template>
  <div class="reset_password">
    <div class="content">
      <div class="signup_form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item label class="form_title">
            <h2>重置密码</h2>
          </el-form-item>

          <el-form-item label prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label prop="code" class="phone_code_wrap">
            <el-input
              v-model="ruleForm.code"
              placeholder="短信验证码"
              autocomplete="off"
            ></el-input>

            <span v-if="!countdown" class="get_phone_code" @click="getSmsCode"
              >获取短信验证码</span
            >
            <span v-else class="get_phone_code count_down"
              >{{ countdownTime }}s后重新获取</span
            >
          </el-form-item>
          <el-form-item label prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
            <div class="tip">不少于6位</div>
          </el-form-item>
          <el-form-item label prop="confirm_password">
            <el-input
              type="password"
              v-model="ruleForm.repeat_password"
              placeholder="请再次输入密码"
              autocomplete="off"
            ></el-input>
            <div class="tip"></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提 交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: "login",
  data() {
    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const reg = /^[1][3,4,5,7,8,9,6][0-9]{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "",
        password: "",
        code: "",
        repeat_password: ""
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ],
        repeat_password: [{ validator: validatePass2, trigger: "blur" }],
        code: [{ required: true, message: "请输入手机验证码", trigger: "blur" }]
      },
      countdown: false,
      countdownTime: 60,
      timer: ""
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    clearInterval(this.timer);
  },
  methods: {
    async getSmsCode() {
      if (!this.ruleForm.phone) {
        this.$toast("请填写手机号");
      } else {
        if (this.ruleForm.phone.length != 11) {
          this.$toast("请输入正确的手机号");
        } else {
          const _this = this;

          const response = await this.$axios.post("/api/sms/", {
            phone: this.ruleForm.phone
          });
          if (response.data.errno == 0) {
            this.$toast("验证码已发送");

            _this.countdown = true;
            _this.timer = setInterval(() => {
              if (_this.countdownTime > 1) {
                _this.countdownTime--;
              } else {
                _this.countdown = false;
                clearInterval(_this.timer);
              }
            }, 1000);
          }
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const response = await this.$axios.post(
            "/api/user/forget_password/",
            this.ruleForm
          );
          if (response.data.errno == 0) {
            this.$toast("密码重置成功");
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.reset_password {
  overflow: hidden;
  background: #fff;
  min-height: 100vh;
  .content {
    padding-top: 0.2rem;
    .signup_form {
      width: 100%;
      margin: 0 auto;
      background: #fff;
      border-radius: 4px;
      .el-button {
        margin-top: 0.6rem;
      }
      .el-form-item {
        padding: 0 0.6rem;
      }
      .form_title {
        border-bottom: 1px solid#DCDFE6;
        margin-bottom: 0.5rem;
        h2 {
          font-size: 0.3rem;
          text-align: center;
          height: 1rem;
          line-height: 1rem;
          font-weight: 600;
          color: #444;
        }
      }
      .tip {
        color: #999;
        font-size: 12px;
        line-height: 24px;
      }
      .el-button--primary {
        width: 100%;
      }
      .phone_code_wrap {
        position: relative;
        .get_phone_code {
          position: absolute;
          right: 10px;
          top: 0;
          color: #910000;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
